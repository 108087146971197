<template>
    <div class="container">
      <service-edit-form
          payload-process="edit"
          :detailed-services-group="detailedServicesGroup"
      />
    </div>
  </template>
  
  <script>
  import ServiceEditForm from "@/components/monitoringServices/form";
  import {mapGetters} from "vuex";
  export default {
    name: "ServiceMonitoringGroupEdit",
    components: {ServiceEditForm},
    data() {
      return {
        isLoading: false
      };
    },
    computed: {
      ...mapGetters('serviceMonitoringGroup', ['detailedServicesGroup'])
    },
    methods: {
      loadDetailedServiceGroup() {
        this.isLoading = true;
        this.$store.dispatch('serviceMonitoringGroup/getServiceGroup', this.$route.params.id).then(() => {
          this.isLoading = false;
        })
      },
    },
    mounted() {
      this.loadDetailedServiceGroup();
    }
  }
  </script>
  
  <style scoped>
  
  </style>